export default class TableAbstract {
  constructor(tableOptions) {
    this.tableOptions = tableOptions;
  }

  // Abstract method to be implemented by subclasses
  dataTableOptions() {
    throw new Error('tableOptions() must be implemented in subclasses');
  }

  replaceMoneyCurrency(moneyString, fromCurrencySymbol, toCurrencySymbol) {
    return moneyString.replace(fromCurrencySymbol, toCurrencySymbol);
  }

  isNumeric(obj) {
    const type = typeof obj;

    return (
      (type === "number" || type === "string") &&
      !isNaN(obj - parseFloat(obj))
    );
  }
}

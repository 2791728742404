export function messageTranslations(emptyTable) {
  if (I18n.locale === "nl") {
    return dutchDatatableTranslations(emptyTable);
  } else if (I18n.locale.includes("fr")) {
    return frenchDatatableTranslations(emptyTable);
  } else if (I18n.locale === "de") {
    return germanDatatableTranslations(emptyTable);
  }
}

function frenchDatatableTranslations(emptyTable) {
  emptyTable = emptyTable || 'Aucune donnée disponible dans le tableau';
  return {
    processing: 'Traitement en cours...',
    search: 'Rechercher&nbsp;:',
    lengthMenu: 'Afficher _MENU_ éléments',
    info: 'Affichage de l\'élément _START_ à _END_ sur _TOTAL_ éléments',
    infoEmpty: 'Affichage de l\'élément 0 à 0 sur 0 éléments',
    infoFiltered: '(filtré de _MAX_ éléments au total)',
    infoPostFix: '',
    infoThousands: ".",
    loadingRecords: 'Chargement en cours...',
    zeroRecords: 'Aucun élément à afficher',
    emptyTable: emptyTable,
    buttons: {
      colvis: 'Changer les colonnes'
    },
    paginate: {
      first: 'Premier',
      previous: 'Précédent',
      next: 'Suivant',
      last: 'Dernier'
    },
    aria: {
      sortAscending: ': activer pour trier la colonne par ordre croissant',
      sortDescending: ': activer pour trier la colonne par ordre décroissant'
    },
    death_beneficiaries: 'Bénéficiaires décès'
  };
}

function dutchDatatableTranslations(emptyTable) {
  emptyTable = emptyTable || "Geen resultaten aanwezig in de tabel";
  return {
    processing: "Bezig...",
    search: "Zoeken:",
    lengthMenu: "_MENU_ resultaten weergeven",
    info: "_START_ tot _END_ van _TOTAL_ resultaten",
    infoEmpty: "Geen resultaten om weer te geven",
    infoFiltered: " (gefilterd uit _MAX_ resultaten)",
    infoPostFix: '',
    infoThousands: ".",
    loadingRecords: "Een moment geduld aub - bezig met laden...",
    zeroRecords: "Geen resultaten gevonden",
    emptyTable: emptyTable,
    paginate: {
      first: "Eerste",
      previous: "Vorige",
      next: "Volgende",
      last: "Laatste"
    },
    aria: {
      sortAscending: ": activeer om kolom oplopend te sorteren",
      sortDescending: ": activeer om kolom aflopend te sorteren"
    }
  };
}

function germanDatatableTranslations(emptyTable) {
  emptyTable = emptyTable || "Keine Daten in der Tabelle vorhanden";
  return {
    processing: "Bitte warten...",
    search: "Suchen:",
    lengthMenu: "_MENU_ Einträge anzeigen",
    info: "_START_ bis _END_ von _TOTAL_ Einträgen",
    infoEmpty: "0 bis 0 von 0 Einträgen",
    infoFiltered: " (gefiltert von _MAX_ Einträgen)",
    infoPostFix: '',
    infoThousands: ".",
    loadingRecords: "Wird geladen...",
    zeroRecords: "Keine Einträge vorhanden.",
    emptyTable: emptyTable,
    paginate: {
      first: "Erste",
      previous: "Zurück",
      next: "Nächste",
      last: "Letzte"
    },
    aria: {
      sortAscending: ": aktivieren, um Spalte aufsteigend zu sortieren",
      sortDescending: ": aktivieren, um Spalte absteigend zu sortieren"
    }
  };
}

import TableAbstract from './table_abstract';
import moment from "moment";
import wNumb from '../wNumb';
import $ from 'jquery'
import { messageTranslations } from '../library_translation';

export default class PePortfolioPositionTable extends TableAbstract {
  dataTableOptions() {
    const moneyOptions = {
      mark: I18n.decimalMark,
      thousand: ' ',
      decimals: 2,
      suffix: ' €',
    };
    const moneyFormat = new wNumb(moneyOptions);
    const percentOptions = {
      thousand: " ",
      mark: ",",
      decimals: 2,
      suffix: "%",
      encoder: a => typeof a === "number" ? a : parseFloat(a) || 0,
    };
    const percentFormatWithSign = new wNumb(percentOptions);
    const { source } = this.tableOptions;
    return  {
      paging: false,
      lengthChange: false,
      info: false,
      searching: false,
      language: messageTranslations(),
      autoWidth: true,
      colResize: false,
      deferRender: true,
      processing: true,
      ajax: {
        url: source,
        dataSrc: ""
      },
      columns: this.positionColumns,
      columnDefs: [
        {
          render: function(data, type, row, meta) {
            var colClasses = meta.settings.aoColumns[meta.col].className;
            if (colClasses && colClasses.includes("percentage") && this.isNumeric(data) && (type === 'display' || type === 'filter')) {
              return percentFormatWithSign.to(parseFloat(data));
            } else if (colClasses && colClasses.includes("value") && this.isNumeric(data) && (type === 'display' || type === 'filter')) {
              var moneyString = moneyFormat.to(data);
              return this.replaceMoneyCurrency(moneyString, '€', row.currency[1]);
            } else if (typeof data === 'boolean') {
              return
            } else {
              return $.fn.dataTable.render.text().display(data, type, row, meta);
            }
          }.bind(this), targets: '_all',
        }
      ],
      responsive: {
        details: {
          renderer: function(api, rowIdx, columns) {
            var data;
            data = $.map(columns, function(col, i) {
              console.log(col.hidden, 'from pe')
              if (col.hidden) {
                if (col.title && col.data) {
                  return $("<tr></tr>").append($("<td></td>", {
                    text: col.title + ": ",
                  })).append($("<td></td>", {
                    text: col.data,
                  })).prop("outerHTML");
                } else if (col.data) {
                  return $("<tr></tr>").append($("<td></td>", {
                    text: col.data,
                  })).prop("outerHTML");
                }
              } else {
                return "";
              }
            }).join("");
            if (data) {
              return $("<table/>").append(data).prop("outerHTML");
            } else {
              return false;
            }
          },
        },
      },
      drawCallback: function (settings) {
        $('.deletePosition, .editPosition').css('cursor', 'pointer');
      },
      initComplete: function (settings) {
        const api = this.api();

        api.on('click', 'tbody td .deletePosition', function () {
          var row = api.row( $(this).closest('tr'));
          this.deletePositionRow(row);
        })
        api.on('click', 'tbody td .editPosition', function () {
          var row = api.row( $(this).closest('tr'));
          this.editPositionRow(row);
        })
      },
      order: [[2, "desc"]],
    }
  }

  get positionColumns() {
    const moneyOptions = {
      mark: I18n.decimalMark,
      thousand: ' ',
      decimals: 2,
      suffix: ' €',
    };
    const moneyFormat = new wNumb(moneyOptions);
    const { readonly } = this.tableOptions;
    return [
      {
        data: 'pe_fund_type',
        title: I18n.t("activerecord.attributes.portfolio_position.pe_fund_type"),
        //width: "15%",
        className: 'align-left'
      },
      {
        data: 'name',
        title: I18n.t("activerecord.attributes.portfolio_position.name"),
        //width: "15%",
        className: 'align-left'
      },
      {
        data: 'current_value',
        title: I18n.t("activerecord.attributes.portfolio_position.pe_fund_nav"),
        //width: "15%",
        className: 'value align-left',
        render: function(data, type, row){
          if(type === "sort" || type === "type"){
            return row.euro_current_value;
          }
          return this.replaceMoneyCurrency(moneyFormat.to(data), '€', row.currency[1]);
        }.bind(this)
      },
      {
        data: 'committed_amount',
        title: I18n.t("activerecord.attributes.portfolio_position.committed_amount"),
        className: 'value align-left none hidden'
      },
      {
        data: 'called_amount',
        title: I18n.t("activerecord.attributes.portfolio_position.called_amount"),
        className: 'value align-left none hidden'
      },
      {
        data: 'remaining_amount',
        title: I18n.t("activerecord.attributes.portfolio_position.remaining_amount"),
        className: 'value align-left none hidden'
      },
      {
        data: 'remaining_amount_call_date',
        title: I18n.t("activerecord.attributes.portfolio_position.remaining_amount_call_date"),
        className: 'align-left none hidden',
        type: "date",
        render: function(data, type, row){
          if(type === "sort" || type === "type"){
            return data;
          }
          var date = moment(data);
          if (date.isValid()) {
            return date.format('DD/MM/YYYY');
          } else {
            return data;
          }

        }
      },
      {
        data: 'distributed_amount',
        title: I18n.t("activerecord.attributes.portfolio_position.distributed_amount"),
        className: 'value align-left none hidden'
      },
      {
        data: 'pe_fund_irr',
        title: I18n.t("activerecord.attributes.portfolio_position.pe_fund_irr"),
        //width: "10%",
        className: 'percentage align-left'
      },
      {
        data: 'pe_fund_multiple',
        title: I18n.t("activerecord.attributes.portfolio_position.pe_fund_multiple"),
        //width: "10%",
        className: 'align-left'
      },
      {
        data: 'pe_fund_vintage',
        title: I18n.t("activerecord.attributes.portfolio_position.pe_fund_vintage"),
        className: 'align-left none hidden',
      },
      {
        data: 'weight',
        title: I18n.t("activerecord.attributes.portfolio_position.weight"),
        //width: "10%",
        className: 'percentage align-left',
      },
      {
        data: 'situation_date',
        title: I18n.t("activerecord.attributes.portfolio_position.situation_date"),
        className: 'align-left',
        //width: "17%",
        type: "date",
        render: function(data, type, row){
          if(type === "sort" || type === "type"){
            return data;
          }
          return moment(data).format('DD/MM/YYYY');
        }
      },
      {
        data: 'pe_fund_strategy',
        title: I18n.t("activerecord.attributes.portfolio_position.pe_fund_strategy"),
        className: 'none hidden',
      },
      {
        data: 'isin',
        title: I18n.t("activerecord.attributes.portfolio_position.isin"),
        className: 'none hidden',
      },
      {
        data: '',
        defaultContent: "",
        orderable: false,
        className: 'align-left all',
        //width: "8%",
        render: function () {
          if (readonly) {
            return ''
          } else {
            return '<span class="d-flex gap-1"><i class="fas fa-pencil editPosition"></i><span></span><i class="ml5 fas fa-circle-xmark deletePosition delete"></i></span>'
          }
        }
      }
    ];
  }
  deletePositionRow(row){
    const { estate_id, asset_id } = this.tableOptions;
    var data = row.data();
    var deleteUrl = `/estates/${estate_id}/portfolio_positions/${data.id}`
    $.ajax({
      url: deleteUrl,
      method: 'delete',
      data: {
        'estate_object_id': asset_id,
      },
      success: () => {
        $(this.element).row(row).remove().draw();
      }
    });
  }


  editPositionRow(row){
    console.log("editPosition called")
    const { estate_id, asset_id } = this.tableOptions;
    var data = row.data();
    var editUrl = `/estates/${estate_id}/portfolio_positions/${data.id}/edit`
    $.ajax({
      url: editUrl,
      method: 'get',
      data: {
        'estate_object_id': asset_id,
      },
      success: () => {
        $(this.element).row(row).data();
      }
    });
  }
}

import TableAbstract from './table_abstract';
import { messageTranslations } from '../library_translation';

export default class AmortizationTable extends TableAbstract {
  dataTableOptions() {
    const { source, report_context } = this.tableOptions;
    const baseOptions = {
      colResize: false,
      language: messageTranslations(),
      responsive: true,
      info: false,
      ordering: false,
      searching: false,
      data: source,
      columns: this.columns,
      iDisplayLength: -1,
      createdRow: function (row, rowData) {
        if (rowData.installment_class) {
          row.classList.add(rowData.installment_class);
        }
        if (rowData.row_id) {
          row.setAttribute('id', rowData.row_id);
        }
      },
      drawCallback: function () {
        const tbody = this.api().table().body();
        tbody.classList.add('frame');
      },
    };

    if (report_context) {
      return Object.assign({}, baseOptions, {
        paging: false,
        autoWidth: false
      });
    } else {
      return Object.assign({}, baseOptions, {
        scrollY: '200px',
        scrollCollapse: true,
        paging: true,
        scroller: true,
        fixedHeader: {
          header: true,
          footer: true
        },
        initComplete: function () {
          // TODO: Fix this for non report context
          const api = this.api();
          const currentRow = api.row('#current-balance');
          api.row(currentRow).scrollTo();
        }
      });
    }
  }

  get columns() {
    const { total_principal, total_interest, total_periodic} = this.tableOptions.totals;
    const translationKey = "product_sheet.estate_liability.amortization_table";
    return [
      {
        data: 'date',
        title: I18n.t(`${translationKey}.date`),
        className: 'align-left',
        id: 'testId'
      },
      {
        data: 'principal',
        title: I18n.t(`${translationKey}.principal`),
        className: 'align-left',
        footer: total_principal,
      },
      {
        data: 'interest',
        title: I18n.t(`${translationKey}.interest`),
        className: 'align-left',
        footer: total_interest,
      },
      {
        data: 'periodic_payment',
        title: I18n.t(`${translationKey}.periodic_payment`),
        className: 'align-left',
        footer: total_periodic
      },
      {
        data: 'balance',
        title: I18n.t(`${translationKey}.balance`),
        className: 'align-left',
      }
    ];
  }
}

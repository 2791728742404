import { Controller } from "@hotwired/stimulus";

import { createApp } from "vue";
import SinglePie from "@laila/components/chart/SinglePie.vue";
import MultiPie from "@laila/components/chart/MultiPie.vue";
import PincodeInput from "@laila/components/pincode-input/PincodeInput.vue";
import NotificationsButton from "@modules/layout/components/buttons/notifications.vue";
import HelpButton from "@modules/layout/components/buttons/help.vue";
import CompanyUserDropdown from "@modules/layout/components/nav-bars/company-user-dropdown.vue";
import AdminUserDropdown from "@modules/layout/components/nav-bars/admin-user-dropdown.vue";
import ProfileUserDropdown from "@modules/layout/components/nav-bars/profile-user-dropdown.vue";
import GroupUserDropdown from "@modules/layout/components/nav-bars/group-user-dropdown.vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import I18nJsBridge from "@src/plugins/i18n-js-bridge";

import DatePicker from "@laila/components/date-picker/DatePicker.vue";

import kebabCase from "lodash/kebabCase";

const COMPONENT_REGISTRY = {
  "date-picker": DatePicker,
  "single-pie-chart": SinglePie,
  "multi-pie-chart": MultiPie,
  "pin-code-input": PincodeInput,
  "notifications-button": NotificationsButton,
  "help-button": HelpButton,
  "company-user-dropdown": CompanyUserDropdown,
  "admin-user-dropdown": AdminUserDropdown,
  "profile-user-dropdown": ProfileUserDropdown,
  "group-user-dropdown": GroupUserDropdown,
};

export default class extends Controller {
  initialize() {
    this.app = null;
  }

  connect() {
    const tagName = this.element.tagName.toLowerCase();
    const vueComponent = COMPONENT_REGISTRY[`${tagName}`];

    this.element.addEventListener("turbo:before-morph-element", e => e.preventDefault());

    if (vueComponent) {
      const propDefinitions = vueComponent.props ? Object.keys(vueComponent.props) : [];
      let props = {};
      propDefinitions.forEach(propKey => {
        if (propKey && this.element.hasAttribute(kebabCase(propKey))) {
          props[propKey] = this.element.getAttribute(kebabCase(propKey));
        } else if (propKey && this.element.hasAttribute(`${kebabCase(propKey)}-json`)) {
          props[propKey] = JSON.parse(this.element.getAttribute(`${kebabCase(propKey)}-json`));
        }
      });

      this.app = createApp(vueComponent, props);

      this.app.use(I18nJsBridge);
      this.app.component("FontAwesomeIcon", FontAwesomeIcon);
      this.app.mount(this.element);
    } else {
      console.warn(`The Vue Component with tag ${tagName} is not registered`);
    }
  }

  disconnect() {
    if (this.app) {
      this.app.unmount();
      this.app = null;
    }
  }
}
